import logo from '../../AfricongoLogo.png'

const Footer = () => {
    const year = new Date().getFullYear();
  
    return <footer style={{textAlign: "center", margin: "50px"}}>
        <div>
        {`Copyright © Kongolaise ${year}`}
        <br/>
        <a href='https://www.africongo.com/'><img src={logo} alt="Logo" className='navbar-logo' width={140} height={50} /></a>
        </div>
        </footer>;
  };
  
export default Footer;