import React, {useContext} from 'react';
// import { ThemeContext } from "../context/theme";

function UserDataPolicy() {
  // context
  // const [theme, setTheme] = useContext(ThemeContext);
  return (
    // <div className="userDataPolicy" style={{ padding: "50px", color: theme === "dark" ? "#00A3E0" : "#8C1D40", backgroundColor: theme === "dark" ? "black" : "white" }}>
    <div className="userDataPolicy" style={{ padding: "50px" }}>
      <h2>User Data Agreement and Policy</h2>
      <p>
        Effective Date: <span>6/30/2023</span>
      </p>
      <p>
        At KONGOLAISE, we are committed to protecting the privacy and security of our users' personal information. This user data policy outlines how we collect, use, and safeguard the data provided by our users. By accessing and using our website, you agree to the terms of this policy.
      </p>
      <h3>1. Information Collection</h3>
      <p>
        We may collect the following types of information from users:
      </p>
      <ul>
        <li>Personal Information: This includes your name, email address, contact information, and any other information you voluntarily provide to us.</li>
        <li>Usage Data: We may collect non-personally identifiable information about how you interact with our website, such as your IP address, browser type, device information, and pages visited.</li>
      </ul>
      <h3>2. Use of Information</h3>
      <p>
        We use the collected information for the following purposes:
      </p>
      <ul>
        <li>To provide and improve our services: We may use your information to personalize your experience on our website, respond to your inquiries or requests, and enhance the functionality and performance of our services.</li>
        <li>To communicate with you: We may send you service-related announcements, updates, and promotional emails that we believe may be of interest to you.</li>
        <li>To ensure website security: We may use your information to detect and prevent any fraudulent or unauthorized activities.</li>
        <li>To comply with legal obligations: We may process your information as required by applicable laws, regulations, or legal proceedings.</li>
      </ul>
      <h3>3. Data Sharing and Disclosure</h3>
      <p>
        We do not sell, rent, or share your personal information with third parties for their marketing purposes. However, we may share your information in the following circumstances:
      </p>
      <ul>
        <li>With trusted service providers: We may engage third-party companies or individuals to assist us in operating our website and providing services to you. These service providers have access to your information only to perform tasks on our behalf and are obligated to protect your data.</li>
        <li>With your consent: We may share your information with third parties if you have given us explicit consent to do so.</li>
        <li>For legal purposes: We may disclose your information to comply with applicable laws, regulations, legal proceedings, or enforceable governmental requests.</li>
      </ul>
      <h3>4. Data Security</h3>
      <p>
        We implement reasonable security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, please be aware that no method of transmission over the internet or electronic storage is completely secure.
      </p>
      <h3>5. Cookies and Tracking Technologies</h3>
      <p>
        We use cookies and similar tracking technologies to enhance your experience on our website, analyze usage patterns, and deliver targeted advertisements. You can control cookies through your browser settings or other tools.
      </p>
      <h3>6. Third-Party Links</h3>
      <p>
        Our website may contain links to third-party websites or services. We are not responsible for the privacy practices or content of these third parties. We encourage you to review their privacy policies before providing any personal information.
      </p>
      <h3>7. Children's Privacy</h3>
      <p>
        Our services are not intended for children under the age of 13. We do not knowingly collect or solicit personal information from children. If you believe that we may have collected personal information from a child, please contact us immediately.
      </p>
      <h3>8. Updates to this Policy</h3>
      <p>
        We may update this user data policy from time to time. The revised policy will be posted on our website with the updated effective date. We encourage you to review this policy periodically to stay informed about how we collect, use, and protect your information.
      </p>
      <p>
        If you have any questions or concerns about this user data policy or our privacy practices, please contact us at <span style={{color: "red"}}>info@africongo.com</span>.
      </p>
      <p>
        This user data policy was last updated on 6/30/2023.
      </p>
    </div>
  );
};

export default UserDataPolicy;